import React from 'react';
import { Timeline } from '@mui/lab';
import tournaments from './data/tournaments';
import TimelineItemComponent from './TimelineItemComponent';

export default function CustomizedTimeline() {
  return (
    <Timeline position="alternate">
      {tournaments.map((tournament, index) => (
        <TimelineItemComponent key={index} tournament={tournament} />
      ))}
    </Timeline>
  );
}

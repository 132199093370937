import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent, TimelineDot } from '@mui/lab';
import AOS from 'aos';
import 'aos/dist/aos.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const TimelineItemComponent = ({ tournament }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <TimelineItem data-aos="fade-up">
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
          {tournament.date}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot>
            <img src="/images/football-icon.png" alt="football icon" style={{ width: '24px', height: '24px' }} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }} onClick={handleOpen}>
          <div className="timeline-item">
            <div className="date-line"></div>
            <div className="tournament-content">
              <img src={tournament.image} alt={tournament.title} className="tournament-image" />
              <div className="tournament-text">
                <Typography variant="h6" component="span">
                  {tournament.title}
                </Typography>
                <Typography>{tournament.blurb}</Typography>
              </div>
            </div>
          </div>
        </TimelineContent>
      </TimelineItem>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style} onClick={(e) => e.stopPropagation()}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-title" variant="h6" component="h2">
            {tournament.title}
          </Typography>
          <img src={tournament.image} alt={tournament.title} className="popup-image" />
          <Typography id="modal-result" sx={{ mt: 2, fontWeight: 'bold' }}>
            {tournament.result}
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {tournament.description}
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default TimelineItemComponent;

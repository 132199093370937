const tournaments = [
  {
    date: 'July 14th 2024',
    title: 'European Championship 2024',
    type: 'European Championship',
    blurb: 'England reaches the final but is defeated by Spain.',
    result: 'Final: England 1-2 Spain',
    description: `England had a remarkable run to the final of the 2024 European Championship. Despite a strong performance, they were narrowly defeated by Spain, who secured the victory with a late goal.`,
    image: '/images/2024.jpg'
  },
  {
    date: 'July 11th 2022',
    title: 'World Cup 2022',
    type: 'World Cup',
    blurb: 'England reaches the quarter-finals but is defeated by France.',
    result: 'Quarter-finals: England 1-2 France',
    description: `England showed great promise throughout the tournament, displaying tactical prowess and teamwork. Despite a valiant effort, they were outmatched by France in the quarter-finals, ending their run in a closely contested match.`,
    image: '/images/2022.jpg'
  },
  {
    date: 'July 11th 2021',
    title: 'European Championship 2020',
    type: 'European Championship',
    blurb: 'England reaches the final but is defeated by Italy.',
    result: 'Final: England 1-1 Italy (2-3 on penalties)',
    description: `England had a historic run to the final of the 2020 European Championship, played in 2021 due to the COVID-19 pandemic. The final at Wembley saw England take an early lead, but they were eventually defeated by Italy in a penalty shootout.`,
    image: '/images/2020.jpg'
  },
  {
    date: 'July 15th 2018',
    title: 'World Cup 2018',
    type: 'World Cup',
    blurb: 'England reaches the semi-finals for the first time since 1990.',
    result: 'Semi-finals: England 1-2 Croatia',
    description: `The team's youthful energy and tactical acumen captivated fans worldwide. England's journey to the semi-finals included memorable victories, and although they fell short, their performance reignited national pride.`,
    image: '/images/2018.jpg'
  },
  {
    date: 'June 27th 2012',
    title: 'European Championship 2012',
    type: 'European Championship',
    blurb: 'England is eliminated by Italy in the quarter-finals.',
    result: 'Quarter-finals: England 0-0 Italy (2-4 on penalties)',
    description: `England's run in the 2012 European Championship ended in the quarter-finals against Italy. The match went to penalties, where England was defeated in a tense shootout.`,
    image: '/images/2012.jpg'
  },
  {
    date: 'July 13th 2014',
    title: 'World Cup 2014',
    type: 'World Cup',
    blurb: 'England fails to advance past the group stage.',
    result: 'Group Stage: England 0-0 Costa Rica',
    description: `Facing tough competition, England struggled to find form and cohesion. The group stage exit was a disappointment, prompting introspection and calls for restructuring within the team.`,
    image: '/images/2014.jpg'
  },
  {
    date: 'July 11th 2010',
    title: 'World Cup 2010',
    type: 'World Cup',
    blurb: 'England suffers dramatic defeat to Germany in the Round of 16.',
    result: 'Round of 16: England 1-4 Germany',
    description: `A controversial match saw England exiting the tournament earlier than expected. The game against Germany was marked by intense moments and debatable decisions, leaving fans and players heartbroken.`,
    image: '/images/2010.jpg'
  },
  {
    date: 'June 21st 2004',
    title: 'European Championship 2004',
    type: 'European Championship',
    blurb: 'England is knocked out by Portugal in the quarter-finals.',
    result: 'Quarter-finals: England 2-2 Portugal (6-5 on penalties)',
    description: `A thrilling tournament run ended in heartbreak as England was ousted by Portugal. The game was a dramatic encounter, filled with twists and turns, and showcased England’s potential on the European stage.`,
    image: '/images/2004.jpg'
  },
  {
    date: 'July 10th 2006',
    title: 'World Cup 2006',
    type: 'World Cup',
    blurb: 'England is eliminated by Portugal in the quarter-finals.',
    result: 'Quarter-finals: England 0-0 Portugal (3-1 on penalties)',
    description: `England faced a resilient Portugal side, ultimately losing in the quarter-finals. The match was fiercely contested, with England showing determination but falling short in the crucial moments.`,
    image: '/images/2006.jpg'
  },
  {
    date: 'June 21st 2002',
    title: 'World Cup 2002',
    type: 'World Cup',
    blurb: 'England is eliminated by Brazil in the quarter-finals.',
    result: 'Quarter-finals: England 1-2 Brazil',
    description: `England's 2002 World Cup journey ended in the quarter-finals against Brazil. Despite an early lead, Brazil's talent and skill turned the game around, leaving England to reflect on what might have been.`,
    image: '/images/2002.jpg'
  },
  {
    date: 'July 4th 1998',
    title: 'World Cup 1998',
    type: 'World Cup',
    blurb: 'England loses to Argentina in a dramatic penalty shootout in the Round of 16.',
    result: 'Round of 16: England 2-2 Argentina (4-3 on penalties)',
    description: `One of the most memorable matches in World Cup history saw England bowing out in a tense penalty shootout against Argentina. The game featured moments of brilliance and controversy, making it a landmark in football history.`,
    image: '/images/1998.jpg'
  },
  {
    date: 'June 30th 1996',
    title: 'European Championship 1996',
    type: 'European Championship',
    blurb: 'England reaches the semi-finals but loses to Germany in a penalty shootout.',
    result: 'Semi-finals: England 1-1 Germany (6-5 on penalties)',
    description: `Playing on home soil, England delivered an inspiring performance but fell short in the semi-finals against Germany. The tournament was marked by passionate support and unforgettable matches, solidifying its place in English football lore.`,
    image: '/images/1996.jpg'
  },
  {
    date: 'July 7th 1990',
    title: 'World Cup 1990',
    type: 'World Cup',
    blurb: 'England reaches the semi-finals but loses to West Germany in a penalty shootout.',
    result: 'Semi-finals: England 1-1 West Germany (4-3 on penalties)',
    description: `England's impressive run was halted by a strong West German team in the semi-finals. The match was a gripping affair, with England displaying skill and tenacity but ultimately succumbing in the penalty shootout.`,
    image: '/images/1990.jpg'
  },
  {
    date: 'June 25th 1988',
    title: 'European Championship 1988',
    type: 'European Championship',
    blurb: 'England is eliminated in the group stage.',
    result: 'Group Stage: England 1-3 Netherlands',
    description: `A challenging group stage saw England exit the 1988 European Championship early. The tournament highlighted the need for tactical adjustments and player development.`,
    image: '/images/1988.jpg'
  },
  {
    date: 'June 26th 1986',
    title: 'World Cup 1986',
    type: 'World Cup',
    blurb: 'England is defeated by Argentina in the quarter-finals, in a match famous for Maradona’s "Hand of God" goal.',
    result: 'Quarter-finals: England 1-2 Argentina',
    description: `A controversial match saw England eliminated by Argentina, featuring the infamous "Hand of God" goal. The game remains one of the most talked-about in World Cup history, with Maradona’s brilliance and controversy taking center stage.`,
    image: '/images/1986.jpg'
  },
  {
    date: 'June 12th 1982',
    title: 'World Cup 1982',
    type: 'World Cup',
    blurb: 'England exits at the second group stage.',
    result: 'Second Group Stage: England 0-0 Spain',
    description: `England had a strong start in the tournament but struggled in the second group stage, ultimately failing to advance further. The team showed promise but couldn’t break through to the knockout rounds.`,
    image: '/images/1982.jpg'
  },
  {
    date: 'June 27th 1980',
    title: 'European Championship 1980',
    type: 'European Championship',
    blurb: 'England fails to advance past the group stage.',
    result: 'Group Stage: England 1-1 Belgium',
    description: `England faced tough competition in the group stage and failed to secure enough points to advance. The tournament highlighted areas for improvement and provided valuable experience for the players.`,
    image: '/images/1980.jpg'
  },
  {
    date: 'June 21st 1970',
    title: 'World Cup 1970',
    type: 'World Cup',
    blurb: 'England is defeated by West Germany in the quarter-finals.',
    result: 'Quarter-finals: England 2-3 West Germany',
    description: `In a rematch of the 1966 final, England faced West Germany in the quarter-finals. Despite a strong performance, England was unable to hold onto their lead and were eliminated in extra time.`,
    image: '/images/1970.jpg'
  },
  {
    date: 'June 5th 1968',
    title: 'European Championship 1968',
    type: 'European Championship',
    blurb: 'England reaches the semi-finals but loses to Yugoslavia.',
    result: 'Semi-finals: England 0-1 Yugoslavia',
    description: `England's run in the 1968 European Championship ended in the semi-finals against Yugoslavia. Despite a strong performance throughout the tournament, they were narrowly defeated. However, they secured third place by defeating the Soviet Union in the subsequent match.`,
    image: '/images/1968.jpg'
  },
  {
    date: 'July 30th 1966',
    title: 'World Cup 1966',
    type: 'World Cup',
    blurb: 'England wins the World Cup, defeating West Germany in the final.',
    result: 'Final: England 4-2 West Germany',
    description: `England's greatest football achievement came on home soil as they won the World Cup in 1966. The final against West Germany is remembered for Geoff Hurst’s hat-trick and the famous “They think it’s all over” commentary.`,
    image: '/images/1966.jpg'
  },
  {
    date: 'June 19th 1962',
    title: 'World Cup 1962',
    type: 'World Cup',
    blurb: 'England exits in the quarter-finals after losing to Brazil.',
    result: 'Quarter-finals: England 1-3 Brazil',
    description: `England faced a formidable Brazil team in the quarter-finals, who would go on to win the tournament. Despite a spirited effort, England was outclassed and eliminated from the competition.`,
    image: '/images/1962.jpg'
  },
  {
    date: 'June 8th 1958',
    title: 'World Cup 1958',
    type: 'World Cup',
    blurb: 'England is eliminated in the group stage.',
    result: 'Group Stage: England 2-2 Austria',
    description: `England struggled in the 1958 World Cup, failing to progress past the group stage. The tournament was a challenging experience that highlighted the need for strategic improvements.`,
    image: '/images/1958.jpg'
  },
  {
    date: 'July 4th 1954',
    title: 'World Cup 1954',
    type: 'World Cup',
    blurb: 'England reaches the quarter-finals but loses to Uruguay.',
    result: 'Quarter-finals: England 2-4 Uruguay',
    description: `England's journey in the 1954 World Cup ended in the quarter-finals against a strong Uruguayan side. The match was a high-scoring affair, but England couldn’t overcome their opponents.`,
    image: '/images/1954.jpg'
  },
  {
    date: 'July 13th 1950',
    title: 'World Cup 1950',
    type: 'World Cup',
    blurb: 'England is eliminated in the group stage after a shock defeat to the USA.',
    result: 'Group Stage: England 0-1 USA',
    description: `One of the most shocking results in World Cup history saw England losing to the USA. The defeat contributed to England’s early exit from the tournament and remains a historic upset.`,
    image: '/images/1950.jpg'
  }
];

export default tournaments;

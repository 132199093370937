import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import CustomizedTimeline from './components/Timeline';
import './App.css';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <h1>England Football Team Timeline</h1>
      </header>
      <CustomizedTimeline />
    </div>
  );
}

export default App;
